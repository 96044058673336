<template>
  <section class="card">
    <link rel="stylesheet" type="text/css" href="/assets/css/relatorios.css">

    <data-header :handleTrocarVisao="handleTrocarVisao"
                 :filtros="filtros"
                 :itemSelecionado="itemSelecionado"
                 :actionButtons="actionButtons">
      <template slot="header">
        <b>Relatório de veículos prontos</b>
      </template>
      <template slot="subheader">
        <br />
        <small>Para extrair o relatório, clique em filtrar ao lado</small>
      </template>
    </data-header>
    <div class="row main-row">
      <filtro-component :filtros="filtros"
                        :params="params"
                        :handleConsultar="handleConsultar">
      </filtro-component>
      <div :class="'h-100 '+(filtros.visible ? 'col-lg-10 p0' : 'col-lg-12')">
        <div :class="'card-body card-tabela ' + (filtros.visible ? '' : 'p0')">
          <div class="h-100">
            <div class="table-responsive records">

              <table id="report_table" class="table table-bordered table-striped table-hover mb-0 table-sm">
                <thead>
                  <tr>


                    <th @click="handleOrdenar('Id')">
                      <span>
                        Despesa
                      </span>
                      <span class="pull-right">
                        <i v-if="params.order == 'Id'"
                           :class="'el ' + (params.direction == 'desc' ? 'el-chevron-down' : 'el-chevron-up')">

                        </i>
                      </span>
                    </th>
                    <th>
                      <span>
                        Est
                      </span>

                    </th>
                    <th>
                      <span>
                        Dt. Status
                      </span>
                    </th>
                    <th>
                      <span>
                        Classif.
                      </span>
                    </th>
                    <th>
                      <span>
                        Status
                      </span>
                    </th>
                    <th>
                      <span>
                        Fornecedor
                      </span>
                    </th>
                    <th>
                      <span>
                        Descrição
                      </span>
                    </th>
                    <th>
                      <span>
                        Aprovador
                      </span>
                    </th>
                    <th>
                      <span>
                        Tipo
                      </span>
                    </th>
                    <th>
                      <span>
                        Valor
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item) in grouped">
                    <tr :key="item[0].Placa">
                      <td colspan="10">
                        <b style="width:10%">Placa: {{item[0].Placa}}</b>
                        <b style="width:27%">{{item[0].Veiculo}}</b>
                        <b style="width:15%">Entrada: {{item[0].DEntradaDisplay}}</b>
                        <b style="width:15%">Pronto: {{item[0].DProntoDisplay}}</b>
                        <b style="width:8%">D.Prep: {{item[0].EP}}</b>
                        <b style="width:8%">D.Est: {{item[0].ES}}</b>
                        <b style="width:15%">Venda: {{item[0].DVendaDisplay}}</b>

                      </td>
                    </tr>
                    <tr v-for="(rq) in item" :key="(rq.Id +'-'+ rq.EstoqueId)">
                      <td>{{rq.Id}}</td>
                      <td>{{rq.Estoque}}</td>
                      <td>{{rq.DataSituacaoDisplay}}</td>
                      <td>{{rq.Classificacao}}</td>
                      <td>{{rq.Status}}</td>
                      <td><span class="wrap">{{rq.Fornecedor}}</span></td>
                      <td><span class="wrap">{{rq.Servico}}</span></td>
                      <td><span class="wrap">{{rq.UsuarioAprovador}}</span></td>
                      <td>{{rq.TipoRequisicao}}</td>
                      <td>{{rq.Valor.toFixed(2).replace('.',',')}}</td>
                    </tr>
                    <tr :key="item[0].Id">
                      <td colspan="10" class="resumo-avaliacao">
                        <b style="width:24%">Avaliador: {{ item[0].Avaliador }}</b>
                        <b style="width:24%">Total Despesa: {{ item.map(c => c.Valor).reduce((partial_sum, a) => partial_sum + a).toFixed(2).replace('.',',') }}</b>
                        <b style="width:24%">Valor Orçado: {{item[0].VlrOrcado ? item[0].VlrOrcado.toFixed(2).replace('.','.') : ''}}</b>
                        <b style="width:24%">Diferença: {{ (item.map(c => c.Valor).reduce((partial_sum, a) => partial_sum + a) - item[0].VlrOrcado).toFixed(2).replace('.','.')}}</b>
                      </td>
                    </tr>
                  </template>
                  <tr>
                    <td colspan="10" style="padding:50px">
                      <table class="table table-bordered table-striped table-hover mb-0 table-sm">
                        <thead>
                          <tr>
                            <th colspan="7" class="text-center">Por classificação</th>
                          </tr>
                          <tr>
                            <th></th>
                            <th v-for="(i,index) in groupedClassificacao" :key="index">{{index}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Quantidade</th>
                            <td v-for="(i,index) in groupedClassificacao" :key="index">{{ i.count }}</td>
                          </tr>
                          <tr>
                            <th>Valor total</th>
                            <td v-for="(i,index) in groupedClassificacao" :key="index">R$&nbsp;{{ i.sum }}</td>
                          </tr>

                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="10" style="padding:50px">
                      <table class="table table-bordered table-striped table-hover mb-0 table-sm">
                        <thead>
                          <tr>
                            <th colspan="7" class="text-center">Resumo</th>
                          </tr>
                          <tr>
                            <th>Avaliador</th>
                            <th>Avaliações</th>
                            <th>Total Despesas</th>
                            <th>Média Depesas</th>
                            <th>Total Avaliado</th>
                            <th>Média Avaliado</th>
                            <th>% Desvio</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(avaliador,index) in groupedAvaliador" :key="index">
                            <td>{{ index }}</td>
                            <td>{{ avaliador.length }}</td>
                            <td>{{ (avaliador.map(c => c.TotalGasto).reduce((partial_sum, a) => partial_sum + a)).toFixed(2) }}</td>
                            <td>{{ (avaliador.map(c => c.TotalGasto).reduce((partial_sum, a) => partial_sum + a) / avaliador.length).toFixed(2) }}</td>
                            <td>{{ (avaliador.map(c => c.VlrOrcado).reduce((partial_sum, a) => partial_sum + a)).toFixed(2) }}</td>
                            <td>{{ (avaliador.map(c => c.VlrOrcado).reduce((partial_sum, a) => partial_sum + a) / avaliador.length).toFixed(2) }}</td>
                            <td>{{ (avaliador.map(c => c.TotalGasto).reduce((partial_sum, a) => partial_sum + a) / avaliador.map(c => c.VlrOrcado).reduce((partial_sum, a) => partial_sum + a)).toFixed(2).replace('0.','') }}%</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination :pagination="result.Pagination"
                        :params="params"
                        @trocar-pagina="(page) => { handleConsultar(page) }">

            </pagination>
          </div>
        </div>

      </div>

    </div>
  </section>
</template>

<script>
  import api from '@/services/api.js';
  import consultar from '@/services/consultar.js';
  import { resolveStatusRequisicaoClass, groupBy } from '@/services/helpers.js';


  //Relatorios Encerramento
  export default {

    components: {
      ...consultar.components,
    },
    data() {
      const that = this;
      return {
        ...consultar.data(),
        url: '/relatorios/pronto',
        clone: '',
        obj: {},
        tipoRequisicaoFiltro: [],
        actionButtons: [
          {
            text: '',
            className: 'print padrao',
            icon: 'fas fa-print',
            //iconColor: 'green',
            color: 'default',
            visible: true,
            title: 'Imprimir relatório',
            callback: that.imprimir
          },
          {
            text: '',
            className: 'excel padrao',
            icon: 'fas fa-file-excel',
            iconColor: 'green',
            color: 'default',
            visible: true,
            title: 'Exportar para excel',
            callback: that.$root.exportCurrentTable

          },
        ],
        itemSelecionado: [0],
        filtros:
        {
          data: [
            {
              id: 'StatusVeiculo',
              title: 'Status Veículo',
              active: false,
              value: [],
              source: [
                //{
                //    id: 'StatusVeiculo',
                //    title: 'Pendente',
                //    value: 0
                //},
                {
                  id: 'StatusVeiculo',
                  title: 'Preparo',
                  value: 1
                },
                {
                  id: 'StatusVeiculo',
                  title: 'Estoque',
                  value: 2
                },
                {
                  id: 'StatusVeiculo',
                  title: 'Vendido',
                  value: 3
                },
              ]
            },
            {
              id: 'StatusRequisicao',
              title: 'Status Requisição',
              active: false,
              source: [{
                id: 'StatusRequisicao',
                title: 'Orçamento',
                value: 0
              },
              {
                id: 'StatusRequisicao',
                title: 'Pendente',
                value: 1
              },
              {
                id: 'StatusRequisicao',
                title: 'Reprovado',
                value: 2
              },
              {
                id: 'StatusRequisicao',
                title: 'Aprovado',
                value: 3
              },
              {
                id: 'StatusRequisicao',
                title: 'Enviado',
                value: 4
              },
              {
                id: 'StatusRequisicao',
                title: 'Executado',
                value: 5
              },
              {
                id: 'StatusRequisicao',
                title: 'Encerrado',
                value: 6
              }
              ],
              value: []

            },
            {
              id: 'TipoServico',
              title: 'Tipo Serviço',
              active: false,
              value: [],
              source: []
            },
            {
                id: 'Classificacao',
                title: 'Classificação Requisição',
                active: false,
                source: [{
                    id: 'Classificacao',
                    title: 'Mecânica',
                    value: 0
                },
                {
                    id: 'Classificacao',
                    title: 'Estética',
                    value: 1
                },
                {
                    id: 'Classificacao',
                    title: 'Revisão',
                    value: 2
                },
                {
                    id: 'Classificacao',
                    title: 'Outros',
                    value: 3
                },
                ],
                value: []

            },
            {
              id: 'DataCadastro',
              title: 'Data Cadastro',
              active: false,
              type: 'date'
            },
            {
              id: 'DataSituacao',
              title: 'Data Situação',
              active: false,
              type: 'date'
            },
            {
              id: 'DPronto',
              title: 'Data Pronto',
              active: false,
              type: 'date'
            },
            {
              id: 'Avaliador',
              title: 'Avaliador',
              active: false,
              value: [],
              source: []
            },

          ],
          visao: [
            {
              id: 0,
              title: 'Todos veículos',
              filtro: [
                {
                  id: 'DPronto',
                  valuede: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0],
                  valueate: ''
                }
              ]
            }
          ],
          visaoSelecionada: 0,
          visible: true
        },
        grouped: [],
        groupedAvaliador: [],
        groupedClassificacao: [],

      };
    },
    methods: {
      ...consultar.methods,
      changeCheckboxCallback: function () { this.itemSelecionado = [0] },
      callback: function (request) {
        this.itemSelecionado = [0];
        this.grouped = groupBy(request.data.Records, 'EstoqueId');
        let dataGeralPorAvaliador = groupBy(request.data.Records, 'Avaliador');
        let base = [];
        for (let i in dataGeralPorAvaliador) {

          for (var j in dataGeralPorAvaliador[i]) {
            if (!base.some(s => s.EstoqueId === dataGeralPorAvaliador[i][j].EstoqueId)) {
              base.push({
                Avaliador: i,
                EstoqueId: dataGeralPorAvaliador[i][j].EstoqueId,
                VlrOrcado: dataGeralPorAvaliador[i][j].VlrOrcado,
                TotalGasto: dataGeralPorAvaliador[i].filter(f => f.EstoqueId === dataGeralPorAvaliador[i][j].EstoqueId).map(x => x.Valor - x.Desconto).reduce((partial_sum, a) => partial_sum + a)
              });
            }
          }
        }
        this.groupedAvaliador = groupBy(base, 'Avaliador');
        this.groupedClassificacao = groupBy(request.data.Records, 'Classificacao');

        
        for (let index in this.groupedClassificacao) {
          let calc = this.groupedClassificacao[index];
          this.groupedClassificacao[index] = { count: calc.length, sum: numberWithCommas(calc.map(c => c.Valor - c.Desconto).reduce((partial_sum, a) => partial_sum + a))};
        }
        console.log(this.groupedClassificacao);
      },
      resolveStatusClass: resolveStatusRequisicaoClass,
      somarNotas: function (results) {
        var total = 0;
        for (var i in results) {
          for (var j in results[i].Estoque.Requisicao) {
            for (var h in results[i].Estoque.Requisicao[j].NotaFiscal) {
              total += results[i].Estoque.Requisicao[j].NotaFiscal[h].Valor;
            }
          }
        }

        return total.toFixed(2).replace('.', ',');
      },
      distinct: (value, index, self) => {
        return self.indexOf(value) === index;
      }
    },
    mounted: function () {
      this.handleTrocarVisao(true);
    },
    beforeMount: function () {
      let filtro = this.filtros.data.filter(f => f.id === 'TipoServico')[0];
      let filtroAvaliador = this.filtros.data.filter(f => f.id === 'Avaliador')[0];
      api.get(this.url + '?preload=true')
        .then(function (response) {
          filtro.source = response.data.tipoRequisicaoFiltro;
          filtroAvaliador.source = response.data.avaliadoresFiltro;
        });
    }
  };
</script>


<style scoped>


  td b {
    display: inline-block;
    text-decoration: underline
  }

  .total {
    text-align: right
  }
</style>
